import React from "react"
import ArrowLink from '../../../components/ArrowLink'
import styles from './styles.module.scss';
import PropTypes from 'prop-types'

const PromoBlocksSection = ({ blocks }) => {
  
  return (
    <section className={styles.promoBlocks}>
      <h2 className={styles.title}>Discover the schools and degree programs that are <strong>right for you</strong></h2>
      
      <div className={styles.promoBlocksInnerWrapper}>
        {blocks.map((item, index) => (
          <div className={styles.promoBlock} key={index}>
            <div className={styles.promoBlockInnerWrapper}>
              <img src={item.image} className={styles.promoImage} alt={item.title}/>
            
              <h3 className={styles.promoTitle}>
                {item.title}
              </h3>
            
              <p className={styles.promoBody}>
                {item.body}
              </p>
            
              <ArrowLink linkPath={item.linkPath} linkTitle={item.linkTitle} className={styles.promoLink} />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
};

PromoBlocksSection.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.exact({
    image: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    linkTitle: PropTypes.string,
    linkPath: PropTypes.string,
  }))
};

PromoBlocksSection.defaultProps = {
  blocks: []
};

export default PromoBlocksSection
